import { render, staticRenderFns } from "./SpaceSchedule.vue?vue&type=template&id=11ff5148&scoped=true"
import script from "./SpaceSchedule.vue?vue&type=script&lang=js"
export * from "./SpaceSchedule.vue?vue&type=script&lang=js"
import style0 from "./SpaceSchedule.vue?vue&type=style&index=0&id=11ff5148&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ff5148",
  null
  
)

export default component.exports