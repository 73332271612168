<script>
// import Reviews from './Reviews.vue'
import SpaceSchedule from "./SpaceSchedule.vue";
import EquipmentList from "./EquipmentList.vue";
import { MyComponent } from "@/components/MyComponent.js";
import jQuery from "jquery";

export default {
  name: "Sections",
  mixins: [MyComponent],
  props: ["space"],
  components: {
    SpaceSchedule,
    // Reviews,
    EquipmentList,
  },
  watch: {
    $route() {
      jQuery("#calendar").fullCalendar("removeEvents");
      this.fetchSpaceLocationData();
    },
  },
};
</script>

<template>
  <div id="sections-holder" class="col-12 text-center">
    <section class="row">
      <div class="col-12">
        <h4>Schedule/Bookings</h4>
      </div>
      <div class="col-12 content">
        <div class="row">
          <space-schedule :space="space" :setAlertMessage="setAlertMessage" />
        </div>
      </div>
    </section>

    <!-- <section class="row"> -->
    <!--   <div class="col-12"> -->
    <!--     <h4>Photos</h4> -->
    <!--   </div> -->
    <!--   <div class="col-12"> -->
    <!--     <router-link class="btn btn-success" :to="{name: 'Photos', params: {slug: space.slug}}">Click to view photos</router-link> -->
    <!--   </div> -->
    <!-- </section> -->

    <section class="row">
      <div class="col-12">
        <h4>Equipment</h4>
      </div>
      <div class="col-12 content">
        <div class="row">
          <equipment-list :space="space" :setAlertMessage="setAlertMessage" />
        </div>
      </div>
    </section>

    <!-- <section class="row"> -->
    <!--     <div class="col-12"> -->
    <!--         <h4>Reviews</h4> -->
    <!--     </div> -->
    <!--     <div class="col-12 content"> -->
    <!--         <div class="row"> -->
    <!--             <reviews :space="space" :setAlertMessage="setAlertMessage" /> -->
    <!--         </div> -->
    <!--     </div> -->
    <!-- </section> -->
  </div>
</template>
