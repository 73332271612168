<script>
import api from "@/api";

export default {
  name: "EquipmentList",
  props: ["space"],
  data: function () {
    return {
      equipmentList: [],
    };
  },
  computed: {
    slug() {
      return this.space.slug;
    },
  },
  methods: {
    getEquipment() {
      api.SpaceLocations.equipment(this.space.id).then((response) => {
        const data = response.data;
        var a = data;
        if (data.results) {
          a = data.results;
        }
        this.equipmentList = a;
      });
    },
  },
  watch: {
    slug() {
      this.getEquipment();
    },
  },
  created() {
    this.getEquipment();
  },
};
</script>

<template>
  <div id="EquipmentList" class="col-12">
    <div class="container">
      <template v-if="equipmentList && equipmentList.length > 0">
        <table class="table table-responsive">
          <thead>
            <th>Name</th>
            <th>Notes</th>
          </thead>
          <tbody>
            <tr v-for="item in equipmentList" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.notes }}</td>
            </tr>
          </tbody>
        </table>
      </template>

      <div v-else class="text-center" id="no_equipment">
        No equipment list available.
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#EquipmentList {
  #no_equipment {
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .item {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(241, 241, 241);
    img {
      max-height: 250px;
    }
    .desc {
      background: white;
    }
    .body {
      color: #666;
    }
  }
}
</style>
