<script>
/* eslint-disable */
import Loader from "vue-element-loading";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import jQuery from "jquery";
import moment from "moment";

import api from "@/api";
import { MyComponent } from "@/components/MyComponent.js";

export default {
  name: "SpaceSchedule",
  mixins: [MyComponent],
  props: ["space"],
  components: {
    Loader,
    FullCalendar,
  },
  data: function () {
    return {
      loading: true,
      spaceConfig: {},
      config: {
        headerToolbar: {
          left: "prev,today,next",
          center: "title",
          right: "",
        },
        validRange: {
          start: new Date(),
        },
        // contentHeight: 500,
        height: "auto",
        events: this.eventsGenerate,

        selectable: true,
        select: this.dateClick,
        dateClick: this.dateClick,

        initialView: "timeGridWeek",
        slotDuration: "1:00",
        slotMinTime: "00:00",
        slotMaxTime: "24:00",
        hiddenDays: [],
        // defaultDate: new Date(),
        // eventLimit: true, // allow "more" link when too many events
        // slotEventOverlap: false,
        // editable: false,
        allDaySlot: false,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
      },
    };
  },

  methods: {
    getCalendarConfig() {
      return api.SpaceLocations.calendarConfig(this.space.id).then((resp) => {
        this.spaceConfig = resp.data;
        this.config.slotMinTime = this.spaceConfig.min_time;
        this.config.slotMaxTime = this.spaceConfig.max_time;
        this.config.hiddenDays = this.spaceConfig.hidden_days;
      });
    },
    hideWarning() {
      jQuery("#SpaceSchedule #warning").hide(400);
    },
    eventsGenerate(timeObj, successCB, failureCB) {
      var p = this;
      p.$emit("loaderChange", true);
      api.Schedules.getBookingsBetween(this.slug, timeObj.start, timeObj.end)
        .then((response) => {
          this.$store.dispatch("General/updateLoading", false);
          this.$emit("loaderChange", false);
          successCB(response.data);
        })
        .catch((err) => {
          console.error(err);
          failureCB(err);
        });
    },
    dateClick(timeObj) {
      if (!this.companyIsPaid) {
        return;
      }
      let endTimeStr = "";
      const startStr = timeObj.startStr;
      if (timeObj.endStr) {
        endTimeStr = timeObj.endStr;
      }
      if (!startStr) {
        return;
      }
      const parts = startStr.split("T");
      const date = parts[0];
      const timeParts = parts[1].split(":");
      const time = `${timeParts[0]}:${timeParts[1]}`;
      let duration = 3;
      if (endTimeStr) {
        const diff = new Date(endTimeStr) - new Date(startStr);
        duration = diff / 1000 / 3600;
      }

      var paramData = {
        date: date,
        time: time,
        duration_in_hours: duration,
        slug: this.slug,
      };

      this.$router.push({
        name: "Booking",
        query: paramData,
        params: {
          slug: this.slug,
        },
      });
    },
  },
  watch: {
    $route() {
      var p = this;
      jQuery(document).ready(function () {
        p.$refs.cal.$emit("refetch-events");
      });
    },
  },
  computed: {
    companyIsPaid() {
      if (this.space?.space_company?.has_paid) {
        return true;
      }
      return false;
    },
    slotMinTime() {
      if (this.space && this.space.start_hour > -1) {
        return this.space.start_hour + ":00";
      }
      return null;
    },
    slotMaxTime() {
      if (this.space && this.space.end_hour > -1) {
        return this.space.end_hour + ":00";
      }
      return null;
    },
    slug() {
      return this.$route.params.slug;
    },
  },
  mounted() {
    setTimeout(this.hideWarning, 5000);
  },
  async created() {
    await this.getCalendarConfig();
    this.loading = false;
  },
};
</script>

<template>
  <div id="SpaceSchedule" class="container">
    <Loader :active="loading" />
    <div
      v-if="companyIsPaid"
      id="warning"
      class="container bg-warning wow fadeInUp animated"
      data-wow-delay="0.3s"
      style="
        visibility: visible;
        -webkit-animation-delay: 0.3s;
        -moz-animation-delay: 0.3s;
        animation-delay: 0.3s;
      "
    >
      <div class="row justify-content-center">
        <div class="col-md-6 col-md-offset-3 text-center">
          <div class="text-center click">
            Tap/Drag/Click on a time slot to make a booking.
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-12" id="calendar_holder">
        <div id="calendar">
          <FullCalendar ref="cal" :options="config" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#calendar_holder {
  padding-left: 0px;
  padding-right: 0px;
}
#calendar {
  background: white;
  max-width: 98%;
  margin-left: 1%;
  .fc-event .fc-bg {
    opacity: 1;
  }
}
#SpaceSchedule {
  padding-bottom: 10px;
  #warning {
    margin-top: 5px;
    margin-bottom: 5px;
    .click {
      margin-top: 10px;
      display: inline-block;
      padding: 5px;
      border-radius: 4px;
      font-size: 14px;
    }
    background: #eee;
  }
  #space_choice {
    margin-top: 10px;
    background: #eee;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .nav-item:hover {
    background-color: white;
    border-radius: 4px;
  }
  .nav-item.first:hover {
    background-color: inherit;
  }
  .router-link-exact-active {
    display: none;
  }
}
</style>
